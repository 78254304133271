<template>
  <!-- <div class="column no-padding"> -->
  <p class="control">
    <b-button iconPack="fas" icon-left="filter" @click.stop.prevent="$emit('apply-filter')"></b-button>
  </p>
  <!-- </div> -->
</template>

<script>
export default {
  name: "FilterButton"
}
</script>